import React, { useRef } from 'react';
import Masonry from 'react-masonry-css';

import SectionMedia from '../../LayoutComponents/SectionMedia';
import * as styles from '../styles.module.css';

const GalleryElement = ({
  gllr,
  section,
  componentIndex,
  subIndex,
  subSubIndex,
  space,
  colors,
  onImageClick,
  images,
}) => {
  const masonryRef = useRef();

  const pictures = [];
  const columnsCount = Number(gllr?.columns || 1);
  const column = section.data[componentIndex]?.data?.[subIndex];

  // if gallery is inside a column
  const divider = 12 / (column?.columnCount || 12);
  const sizes = [
    `(min-width: 992px) ${100 / columnsCount / divider}vw`,
    `(min-width: 768px) ${100 / columnsCount}vw`,
    `(min-width: 576px) ${columnsCount === 1 ? 100 : 50}vw`,
    `100vw`,
  ].join(', ');

  const { crop, lightbox } = gllr || {};

  gllr?.data?.forEach((item, i) =>
    pictures.push(
      <div key={`${section._id}_Image_${i}_${componentIndex}_${subIndex}_${subSubIndex}`}>
        <div
          className={subIndex == null ? styles.customImageWrapper : styles.imageWrapper}
          style={lightbox ? { cursor: 'pointer' } : undefined}
        >
          <SectionMedia
            mediaType={item.icon ? 'ICON' : 'IMAGE'}
            wrapperStyleName={crop ? 'imageWrapper100' : 'imageContent5'}
            imageStyleName={crop ? 'galleryImg' : 'imageFull'}
            sizes={sizes}
            src={item.CDNLink}
            alt={item.alt}
            data={item}
            sectionId={section._id}
            colors={colors}
            onImageClick={lightbox ? onImageClick : undefined}
            images={images}
          />
        </div>
      </div>,
    ),
  );

  let gallery;
  const md = Number(columnsCount) === 1 ? 1 : 2;

  if (crop !== true) {
    const breakpointColumnsObj = {
      default: Number(columnsCount),
      768: md,
      576: 1,
    };

    gallery = (
      <Masonry
        ref={masonryRef}
        breakpointCols={breakpointColumnsObj}
        style={{ display: 'flex', width: '100%' }}
        className=""
        columnClassName=""
      >
        {pictures.map((pic, i) => (
          <div key={`${section._id}_Gallery_Image${componentIndex}${i}`} className={styles.masonryImageWrapper}>
            {pic}
          </div>
        ))}
      </Masonry>
    );
  } else {
    gallery = [];
    const className = `col-12 col-sm-${12 / md} col-md-${12 / Number(columnsCount)}`;
    pictures.forEach((p, i) => {
      const pic = (
        <div className={className} key={`${section._id}_Gallery_Image${componentIndex}${i}`}>
          {p}
        </div>
      );

      return gallery.push(pic);
    });
  }

  return (
    <div
      className={subIndex == null ? 'col-10' : styles.gallery}
      style={space ? { marginBottom: `${space}px`, marginTop: `${space}px` } : undefined}
    >
      <div className="row" style={subIndex != null && crop ? { width: '100%' } : undefined}>
        {gallery}
      </div>
    </div>
  );
};

export default GalleryElement;
