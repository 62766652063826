// extracted by mini-css-extract-plugin
export var alignLeft = "r_p6 d_fn d_bF d_dt";
export var alignCenter = "r_bN d_fp d_bC d_dv";
export var alignRight = "r_p9 d_fq d_bG d_dw";
export var menuContainer = "r_q3 d_dV";
export var menuContainerFull = "r_q4 d_dS";
export var menuMainHeader = "r_jz d_jz d_v d_c2";
export var menuComponentWrapper = "r_jD d_jD d_ct";
export var menuComponentWrapperDesign2 = "r_jF d_jF d_cx";
export var menuComponentText = "r_jB d_jB d_cm";
export var menuComponentTextDesign2 = "r_jC d_jC d_cr";
export var menuImageWrapperDesign2 = "r_jG d_jG d_v d_Y";