// extracted by mini-css-extract-plugin
export var lbContainerOuter = "B_tX";
export var lbContainerInner = "B_tY";
export var movingForwards = "B_tZ";
export var movingForwardsOther = "B_t0";
export var movingBackwards = "B_t1";
export var movingBackwardsOther = "B_t2";
export var lbImage = "B_t3";
export var lbOtherImage = "B_t4";
export var prevButton = "B_t5";
export var nextButton = "B_t6";
export var closing = "B_t7";
export var appear = "B_t8";