// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "v_rB d_bC";
export var storyRowWrapper = "v_hv d_hv d_bJ";
export var storyLeftWrapper = "v_rC d_by d_bN";
export var storyWrapperFull = "v_rD d_cC";
export var storyWrapperFullLeft = "v_nL d_cC d_by d_bN d_bC";
export var contentWrapper = "v_px d_hw";
export var storyLeftWrapperCenter = "v_rF d_hx d_by d_bC d_bN d_bH";
export var storyRightWrapperCenter = "v_rG d_hC";
export var storyHeader = "v_rH d_hB d_v d_cr";
export var storyHeaderCenter = "v_hz d_hz d_v d_cr d_dv d_bx";
export var storyParagraphCenter = "v_hy d_hy d_bx d_dv";
export var storyBtnWrapper = "v_rJ d_d1 d_d0 d_v d_by d_bF";
export var storyBtnWrapperCenter = "v_hG d_hG d_d0 d_v d_by d_bC";
export var imageWrapper = "v_rK d_ff d_Y";
export var imageWrapperFull = "v_rL d_v d_G d_bd d_Y";