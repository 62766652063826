// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "s_q5 d_gs d_cr";
export var heroHeaderCenter = "s_gt d_gt d_cr d_dv";
export var heroHeaderRight = "s_gv d_gv d_cr d_dw";
export var heroParagraphLeft = "s_q6 d_gp d_cv";
export var heroParagraphCenter = "s_gq d_gq d_cv d_dv";
export var heroParagraphRight = "s_gr d_gr d_cv d_dw";
export var heroBtnWrapperLeft = "s_q7 d_d1 d_d0 d_v d_by d_bF";
export var heroBtnWrapperCenter = "s_q8 d_d2 d_d0 d_v d_by d_bC";
export var heroBtnWrapperRight = "s_q9 d_d3 d_d0 d_v d_by d_bG";
export var overlayBtnWrapper = "s_rb d_gn d_Z d_6 d_7 d_8 d_bm d_cb";
export var design4 = "s_rc d_gm d_Z d_6 d_7 d_bm";
export var heroExceptionSmall = "s_rd x_rd";
export var heroExceptionNormal = "s_rf x_rf";
export var heroExceptionLarge = "s_rg x_rg";
export var Title1Small = "s_rh x_rh x_rS x_rT";
export var Title1Normal = "s_rj x_rj x_rS x_rV";
export var Title1Large = "s_rk x_rk x_rS x_rW";
export var BodySmall = "s_rl x_rl x_rS x_r9";
export var BodyNormal = "s_rm x_rm x_rS x_sb";
export var BodyLarge = "s_rn x_rn x_rS x_sc";