// extracted by mini-css-extract-plugin
export var quoteParagraphLeft = "t_rp d_gQ d_cv d_dt";
export var quoteParagraphCenter = "t_gR d_gR d_cv d_dv";
export var quoteParagraphRight = "t_gS d_gS d_cv d_dw";
export var quoteRowLeft = "t_rq d_bF";
export var quoteRowCenter = "t_rr d_bC";
export var quoteRowRight = "t_rs d_bG";
export var quoteWrapper = "t_gN d_gN d_v d_cC d_by d_bN d_bC";
export var quoteContentWrapper = "t_gP d_gP";
export var quoteExceptionSmall = "t_rt x_rt";
export var quoteExceptionNormal = "t_rv x_rv";
export var quoteExceptionLarge = "t_rw x_rw";
export var quoteAuthorExceptionSmall = "t_rx x_rx";
export var quoteAuthorExceptionNormal = "t_ry x_ry";
export var quoteAuthorExceptionLarge = "t_rz x_rz";