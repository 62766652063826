// extracted by mini-css-extract-plugin
export var warningBackgroundlight = "f_mp";
export var warningBackgrounddark = "f_mq";
export var warningBackgroundcustom = "f_mr";
export var bottom = "f_gF";
export var modal = "f_ms";
export var container = "f_mt";
export var btnWrapper = "f_d1 d_bC d_bN d_bH";
export var elementWrapper = "f_mv d_bC d_bN";
export var titleWrapper = "f_mw d_bC d_bN";
export var cookieButtons = "f_mx d_by d_bC d_bN d_bH";
export var btn = "f_my d_by d_bC d_bN";
export var link = "f_mz";
export var decline = "f_mB f_my d_by d_bC d_bN";
export var row = "f_mC";