// extracted by mini-css-extract-plugin
export var tileContent = "q_qP d_v d_G d_Y";
export var teamTextLeft = "q_qQ d_dt";
export var teamTextCenter = "q_qR d_dv";
export var teamTextRight = "q_qS d_dw";
export var alignLeft = "q_p6 d_v d_by d_fn d_bF d_dt";
export var alignCenter = "q_bN d_v d_by d_fp d_bC d_dv";
export var alignRight = "q_p9 d_v d_by d_fq d_bG d_dw";
export var teamContainer = "q_qT d_dV";
export var teamContainerFull = "q_qV d_dS";
export var teamRowWrapper = "q_qW d_cb";
export var teamTileWrapper = "q_j1 d_j1 d_Y d_ct";
export var teamTileSquareWrapper = "q_qX d_j2 d_Y d_ct";
export var teamTileRoundWrapper = "q_j2 d_j2 d_Y d_ct";
export var teamTileNoGuttersWrapper = "q_j3 d_j3 d_Y";
export var teamHoverNoGutters = "q_j4 d_j4 d_Z d_v d_G d_by d_bC d_bN d_bl";
export var teamImageText = "q_j9 d_j9 d_bk d_v d_bx";
export var teamInfoWrapperSquare = "q_qY d_j5";
export var teamInfoWrapperRound = "q_j5 d_j5";
export var teamInfoWrapper = "q_qZ d_j6 d_Z";
export var teamInfoWrapperNoGutters = "q_j7 d_j7 d_v d_G d_bC d_bL d_bH d_c6";
export var teamImgWrapper = "q_jX d_jX";
export var teamImgWrapperAlt = "q_jY d_jY";
export var teamImgWrapperNoGutters = "q_j8 d_j8";
export var teamHeader = "q_q0 d_cv";
export var teamHeaderAlt = "q_q1 d_cv";
export var teamHeaderNoGutters = "q_q2 d_cv d_cC";