// extracted by mini-css-extract-plugin
export var noPadding = "w_fh d_fh";
export var videoFrameWrapper = "w_rM d_kR d_b4";
export var videoIframeStyle = "w_pG d_d4 d_v d_G d_bx d_b0 d_Q";
export var videoLayoutRight = "w_rN d_kQ d_bJ";
export var videoInnerWrapper = "w_rP d_fb d_d9 d_N d_Y d_by d_bC d_bN d_b4";
export var videoText = "w_rQ d_dv d_v";
export var videoInnerInnerWrapper = "w_rR d_by d_bC d_bH";
export var btnWrapper = "w_d1 d_v d_by d_bC d_ch";
export var btn = "w_my d_b0 d_by d_bC d_bN d_cG d_b5 d_dz d_m x_sC x_sl";
export var icon = "w_pZ d_cB";